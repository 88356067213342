<script>
import ModalAlertComponent from '../../shared-component/modal-alert-component/modal-alert-component.vue'
import ModalLoginPassComponent from '../../shared-component/modal-login-pass-component/modal-login-pass-component.vue'
import InputValidatorsComponent from '../../shared-component/input-validators-component/input-validators-component.vue'
import SiretInput from '../../shared-component/siret-input/siret-input.vue'

import {FormValidatorsMixin} from '../../../mixins/form-validators-mixin.js'
import {RegexMixin} from '../../../mixins/regex-mixin.js'

import AuthService from '../../../services/AuthService.js'
import UsersService from '../../../services/UsersService.js'
import StorageService from '../../../services/StorageService.js'
import {User} from '../../../model/User.js'
import router from '../../../router'
import NewsLetterService from '../../../services/NewsLetterService'

export default {
    name: "MyAccountInformationsPage",
    components: {
        ModalAlertComponent,
        ModalLoginPassComponent,
        InputValidatorsComponent,
        SiretInput
    },
    mixins: [FormValidatorsMixin, RegexMixin],
     props: ['templateMode']   ,
    data() {
        return {
            inputFileRef: undefined,
            uploadedProfilPicture: [],
            // Sould come from backend
            pro: true,
            // Form values
            form: {
                userName: '',
                email: '',
                tel: '',
                siret: '',
                website: '',
                pwd: '',
                newPassword: '',
                newPasswordConfirm: '',
            },
            // Form input field, valid or not
            formValid: {
                userName: false,
                email: false,
                tel: false,
                siret: false,
                website: false,
                pwd: false,
                newPassword: false,
                newPasswordConfirm: false,
            },
            // User pciture preview
            preview: [],
            // Confirmation modal message
            modalMessage: '',
            user: new User(),
            updating:false,
            passwordModificationError:undefined,
            modifyingPassword:false,
            aucuneInfoModifie:true,
            modalConfimationMode:1, // 1 == suppression de photo de profil, 2 == suppression de compte
            uploadingPhoto:false,
            errorDeleteAccount:undefined,
        }
    },

    created(){
        this.$emit('headerState', 8);
        let currentUserId = AuthService.getCurrentUserId();

         UsersService.getSingleUser(currentUserId).then((user)=>{
            this.user = user;
            this.loadPhoto();
           
        }).then(()=>{
            this.loadUserToForm(this.user);
        });
       
    },
    mounted() {
        this.inputFileRef = this.$refs.fileInput
        this.aucuneInfoModifie = true;
        
    },
    methods: {
        quitNewsLetter(){
            console.info("quitNewsLetter");
            alert("Vous ne recevrez plus de newsletter");
            NewsLetterService.deleteEmail(this.user.email);
            

        },

        loadUserToForm(user){
            this.$refs.userNameInput.inputValue = user.nom;
            this.$refs.emailInput.inputValue = user.email;
            this.$refs.phoneInput.inputValue = user.phone;

            this.$refs.userNameInput.sendValidate();
            this.$refs.emailInput.sendValidate();
            this.$refs.phoneInput.sendValidate();

            if (user.pro){
                this.$refs.siretInput.inputValue = user.siret;
                this.$refs.websiteInput.inputValue = user.web;
                this.$refs.siretInput.sendValidate();
                this.$refs.websiteInput.sendValidate();
            }
           
        },

        // Handle user picture preview & deletion
        uploadFile() {
            this.inputFileRef.$el.click()
        },
        onFileChange(e) {
            this.uploadingPhoto = true;
            const file = e.target.files[0];
            this.preview = []
            console.info("uploading File " + file);
            this.uploadPhoto(file);  
        },

        uploadPhoto(file){
             StorageService.resizeAndUploadProfilePhoto(this.user,file,64,64).then(()=>{
                this.user.photo = true;
                UsersService.setUser(this.user);
                this.loadPhoto();
                 this.uploadingPhoto = false;
            },
            (error)=>{
                console.error("erreur pendant upload photo : " + error);
                 this.uploadingPhoto = false;
            });
        },
  
        loadPhoto(){
            if (this.user.photo){
                 StorageService.getProfilePhotoDownloadURL(this.user).then((url)=>{
                     this.preview.push(url);
                });
            } else {
                this.preview = [];
            }
        },
        
        // Modal show method
        deletionConfirmation(modalSwitch) {
            console.info("deletionConfirmation : "+modalSwitch);
            switch(modalSwitch) {
                case 'deleteImg':
                    this.modalConfimationMode = 1;
                    this.modalMessage = "Êtes vous sur de vouloir supprimer votre photo de profile ?"
                    this.$refs.modalAlert.showModal();
                    break
                case 'deleteProfile':
                    this.modalConfimationMode = 2;
                    this.modalMessage = "Êtes vous sur de vouloir supprimer votre compte ?"
                    this.$refs.modalAlert.showModal()
                    break
                default:
                    this.modalMessage = ''
                    break
            }
            
        },
        onFormSubmit(){
            console.info("onFormSubmit");
            this.updating = true;
            this.user.nom = this.$refs.userNameInput.inputValue;
            this.user.email = this.$refs.emailInput.inputValue;
            this.user.phone = this.$refs.phoneInput.inputValue;
            if (this.user.pro){
                this.user.siret = this.$refs.siretInput.inputValue;
                this.user.web = this.$refs.websiteInput.inputValue;
            }

            UsersService.setUser(this.user).then(()=>{
                this.updating = false;
            });
        },
        isInfosFormOk(){
            if (this.aucuneInfoModifie) return false;

            let infosFormOK = this.formValid.userName && this.formValid.email && this.formValid.tel;
            infosFormOK = this.form.userName.length>0 && this.form.email.length>0 && this.form.tel && this.form.tel.length > 0;
            if (this.user.pro){
                infosFormOK = infosFormOK && this.formValid.siret && this.formValid.website;
                infosFormOK = infosFormOK && this.form.siret.length>0 && this.form.website.length>0;
            }
            console.info("infosFormOK = " + infosFormOK);
            return infosFormOK;
        },

        isImageLoaded(){
            return this.preview.length > 0;
        },

        onModifyPassword(){
            this.passwordModificationError = undefined;
            this.modifyingPassword = true;
            if (this.formValid.pwd && this.formValid.pwd){
                AuthService.changePassword(this.form.pwd,this.form.newPassword).then(()=>{
                   this.modifyingPassword = false;
                },
                (error)=>{
                    this.modifyingPassword = false;
                    this.passwordModificationError = error;
                })
            }
        },

        afterSetValidatedState(validated, inputValue, fieldName){
            console.info("Field " + fieldName + " is " + validated?" validated !":"wrong !");
            if (fieldName == 'userName' && this.user.nom != inputValue){
                this.aucuneInfoModifie = false;
            }else if(fieldName == 'email' && this.user.email != inputValue){
                this.aucuneInfoModifie = false;
            } else if (fieldName == 'tel' && this.user.phone != inputValue){
                this.aucuneInfoModifie = false;
            } else if (this.user.pro && fieldName == 'siret' && this.user.siret != inputValue){
                this.aucuneInfoModifie = false;
            } else if (this.user.pro && fieldName == 'website'&& this.user.website != inputValue){
                this.aucuneInfoModifie = false;
            }
        },

        isPasswordFormOk(){
            let passwordFormOK = this.formValid.pwd && this.formValid.newPassword && this.formValid.newPasswordConfirm && this.form.pwd.length >=6 && this.form.newPassword.length >=6 && this.form.newPasswordConfirm.length >=6;
            console.info("passwordFormOK = " + passwordFormOK);
            return passwordFormOK;
        },

      
        modalOk() {
            if (this.modalConfimationMode==1){
                StorageService.deleteProfilePhoto(this.user).then(()=>{
                    this.preview = []
                    this.user.photo = false;
                    UsersService.setUser(this.user);
                 });
            } else {
                this.deleteAccount();
            }
            
        },
        modalCancel(){

        },

         deleteAccount(){
            console.info("deleteAccount");
           
            UsersService.deleteUser(this.user).then(()=>{
                 console.info("UsersService.deleteUser success");
                AuthService.deleteUser().then(()=>{
                    console.info("AuthService.deleteUser success");
                    console.info("on retourne a l'accueil");
                    return router.push({ path: '/'});
                },(error) =>{
                    console.info("deleteUser error : " + error);
                     this.errorDeleteAccount = "erreur lors de suppression de compte : " + error;
                });
            },(error)=>{
                console.info("erreur lors de suppression de compte : " + error);
                this.errorDeleteAccount = "erreur lors de suppression de compte : " + error;
            });
        },

        deleteAcountOk(password){
              console.info("deleteAcountOk");
              UsersService.deleteUser(this.user,password).then(()=>{
                 console.info("UsersService.deleteUser success");
                AuthService.deleteUser().then(()=>{
                    console.info("AuthService.deleteUser success");
                    console.info("on retourne a l'accueil");
                    return router.push({ path: '/'});
                },(error) =>{
                    console.info("deleteUser error : " + error);
                    this.errorDeleteAccount = "erreur lors de suppression de compte : " + error;
                });
            },(error)=>{
                console.info("erreur lors de suppression de compte : " + error);
                this.errorDeleteAccount = "erreur lors de suppression de compte : " + error;
            });

        },
        deleteAcountCancel(){
            console.info("deleteAcountCancel");
        },
        askLoginPassForAccountDeletion(){
            if (AuthService.isGoogleAccount()){
                 this.deletionConfirmation('deleteProfile');
            } else if (AuthService.isFacebookAccount()){
                this.deletionConfirmation('deleteProfile');
            } else {
                this.$refs.modalLoginPass.showModal();
            }
           

            
        },
         gePageStyle(){
            if (this.templateMode < 3){
                return "max-width:500px; margin: 0 auto;position:relative;";
            } else {
                return "";
            }
        }
        
    }
};
</script>

<style lang='scss'>
    @import "my-account-informations-page.scss";
</style>

<template src="./my-account-informations-page.html"></template>