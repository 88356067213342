<template>
    <div class="modal-login-pass-component-container">
        <b-modal v-model="showed"
            id="LoginPass-modal" 
            :centered="true" 
            footer-class="centered-footer"
            :hide-header="false"
            ok-title="Valider"
            cancel-title="Annuler"
            cancel-variant="outline-secondary"
            :title="modalMessage"
            @ok="userValidation"
            @cancel="userCancel">
            <form ref="formLoginPass" @submit.stop.prevent="handleSubmit">
                <b-form-group
                    :state="passwordState"
                    label="Password"
                    label-for="password-input"
                    invalid-feedback="le mot de passe est compris entre 6 et 18 caractères.">
                    <b-form-input
                    id="password-input"
                    v-model="password"
                    :state="passwordState"
                    required
                    ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "ModalLoginPassComponent",
    components: {
    },
    props: ['modalMessage','okFunc','cancelFunc'],
    data() {
      return {
        showed:false,
        password: '',
        passwordState: null,
      }
    },
    methods: {
        showModal() {
            this.password = '';
            this.passwordState = null;
            this.showed = true;
        },
        userValidation(bvModalEvt) {
            // Trigger submit handler
            this.handleSubmit(bvModalEvt);
            this.showed = false;
        },
        userCancel() {
            console.log('cancelled modal action')
            this.cancelFunc();
            this.showed = false;
        },
        checkFormValidity() {
            const valid = this.$refs.formLoginPass.checkValidity()
            this.passwordState = valid && this.password.length >= 6;
            return valid && this.password.length >= 6;
        },
        handleSubmit(bvModalEvt) {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                 // Prevent modal from closing
                bvModalEvt.preventDefault();
                return;
            }
            this.okFunc(this.password);

            // Hide the modal manually
           // this.$nextTick(() => {
             //   this.$bvModal.hide('modal-prevent-closing')
          //  })
        }
    }
};
</script>

<style lang="scss">
    @import "modal-login-pass-component.scss";
</style>