<template>
    <b-form-group class="input-validators-component-container ml-auto mr-auto" >
        <ValidationProvider :name="name" :rules="rule" v-slot="{ errors }">
            <b-input-group-append class="mt-2 mb-0">
                <b-form-input 
                    @keyup="sendValidate()" 
                    v-model="siret" 
                    variant="primary" 
                    class="input-validator"
                    :id="'input' + name" 
                    type="text"
                    :placeholder="placeholder"
                    :autocomplete="autocomplete">
                </b-form-input>
            </b-input-group-append>
            <span class="error-message">{{ errors[0]?"Le numéro de SIRET doit être composé de 14 chiffres":"" }}</span>
        </ValidationProvider>
    </b-form-group>
</template>

<script>
import { ValidationProvider, extend, localize, validate } from 'vee-validate';
// eslint-disable-next-line no-unused-vars
import {regex} from 'vee-validate/dist/rules';
import fr from 'vee-validate/dist/locale/fr.json';

// Set default language to fr
localize('fr', fr);
extend('regex', regex)

export default {
    name: "SiretInput",
    components: {
        ValidationProvider
    },
    // Take 3 props, validator rule, name of field (used in name attribute & input id)
    // and placeholder value
    props: ['name', 'placeholder', 'autocomplete', 'initialValue'],
    data : () => {
        return {
            rule: { regex: /^[0-9][0-9][0-9] [0-9][0-9][0-9] [0-9][0-9][0-9] [0-9][0-9][0-9][0-9][0-9]$/ },
            inputValue : '',
            realInputValue: '',
        }
    },
    computed: {
        siret: {
            // getter
            get: function () {
                return this.inputValue;
            },
            // setter
            set: function (newValue) {
                this.realInputValue = this.realSiret(newValue);
                this.inputValue = this.siretToNiceSiret(this.realInputValue);  
            }
        }
    },
    created(){
        if (this.initialValue){
            this.inputValue = this.initialValue;
        } 
    },
    methods: {
        realSiret(str){
            return str.replaceAll(' ', '');
        },
        siretToNiceSiret(str){
            if (str.length> 9){
                return str.substr(0,3) + " " + str.substr(3,3) + " " + + str.substr(6,3) + " " + str.substr(9);
            } if (str.length> 6){
                return str.substr(0,3) + " " + str.substr(3,3) + " " + + str.substr(6);
            } if (str.length> 3){
                return str.substr(0,3) + " " + str.substr(3);
            } else {
                return str;
            }
        },
        sendValidate() {
            validate(this.inputValue, this.rule).then(result => {
                console.log('sendValidate to parent :', result.valid)
                this.$emit('validationState', result.valid, this.realInputValue, this.name)
            })
        },
    }
};
</script>

<style lang="scss">
    @import "siret-input.scss";
</style>
